import { render, staticRenderFns } from "./JoinAgent.vue?vue&type=template&id=04408434&scoped=true&"
import script from "./JoinAgent.vue?vue&type=script&lang=js&"
export * from "./JoinAgent.vue?vue&type=script&lang=js&"
import style0 from "./JoinAgent.vue?vue&type=style&index=0&id=04408434&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04408434",
  null
  
)

export default component.exports