<template>
    <div class="block hzzp-block">
        <!-- <div v-if="type != 'self'" class="hzzp-header flex flex-align-center">
            <div class="state-icon">
                <img v-if="item.stat==1" src="../../assets/images/factory/icon-hzz.png">
                <img v-if="item.stat>1" src="../../assets/images/factory/icon-hzz1.png">
            </div>
            
            <div v-if="item.design_comp" class="comp-name">
                <span>设计公司：</span>{{item.design_comp.name}}</div>
            <div class="outdate-time">
                <span>到期时间：</span>{{item.expire_at | moment("YYYY/MM/DD")}}
            </div>
        </div> -->
        <div class="hzzp-info flex">
            <div @click="gotoDetail()" style="width:230px;height:230px;" class="img-left">
                <div v-if="item.photo_render && item.photo_render.length>0" class="bg_img"
                    :style="{backgroundImage: 'url(' + imgUrl+item.photo_render[0]+'!width_250px' + ')'}"></div>
            </div>
            <div class="info-right flex-1">
                <div class="info-right-top">
                    <div @click="gotoDetail()" class="flex">
                        <div class="tit ellipsis">{{item.name}}</div>
                    </div>
                    <div class="cat">
                        <span style="margin-right:8px" v-if="item.cat">{{item.cat.name}}</span>
                        <span v-if="item.style">{{item.style.name}}</span>
                    </div>
                    <template v-if="type != 'self'">
                        <div class="state-icon">
                            <el-popover
                                placement="top-start"
                                width="100%"
                                trigger="hover"
                                :content="'到期时间：'+ item.expire_at">
                                <img v-if="item.stat==1" slot="reference" src="../../assets/images/factory/icon-hzz.png">
                                <img v-if="item.stat>1" slot="reference" src="../../assets/images/factory/icon-hzz1.png">
                            </el-popover>
                        </div>
                        
                        <div v-if="item.design_comp"><span>设计公司：</span>{{item.design_comp.name}}</div>
                        <!-- <div v-if="item.expire_at"><span>到期时间：</span>{{item.expire_at | moment("YYYY/MM/DD")}}</div> -->
                    </template>
                    <div v-if="type == 'self' && item.module_no">型号：<span style="color:#4D4D4D;">{{item.module_no}}</span></div>
                    <!-- <div>
                        已有 {{item.design_market_n_visited || 0}} 人查看
                    </div> -->
                </div>
                <div class="info-right-bottom">
                    <div class="state flex">
                        <div class="flex flex-align-center">
                            <span v-if="item.photo_sample && item.photo_sample.length>0" class="state-item green">
                                <i class="el-icon-check"></i>已打样
                            </span>
                            <span v-else class="state-item">
                                <i class="el-icon-close"></i>未打样
                            </span>
                            <el-button v-if="type === 'self' && item.stat==2 || type != 'self' && item.stat==1" 
                                style="margin-left:5px;" @click="authentication()" type="text" size="small">
                                <span v-if="item.photo_sample && item.photo_sample.length>0">重新打样</span>
                                <span v-else>打样认证</span>
                            </el-button>
                        </div>
                        <div class="flex flex-align-center">
                            <span v-if="item.is_bom == 1" class="state-item green">
                                <i class="el-icon-check"></i>已填写产品BOM
                            </span>
                            <span v-if="item.is_bom == 0" class="state-item">
                                <i class="el-icon-close"></i>未填写产品BOM
                            </span>
                            <el-button v-if="type === 'self' && item.stat==2 || type != 'self' && item.stat==1" 
                                style="margin-left:5px;" @click="fillBom(item)" type="text" size="small">
                                <span v-if="item.is_bom == 1">重填BOM</span>
                                <span v-if="item.is_bom == 0">填写BOM</span>
                            </el-button>
                        </div>
                        <div>
                            <el-button v-if="type === 'self'&&item.stat==2" @click="edit(item)" 
                                type="text" size="small">编辑产品信息</el-button>
                            <el-button v-if="type === 'self'&&item.stat==2" @click="del(item)" 
                                style="color:#F56C6C" type="text" size="small">删除</el-button>
                            <el-button v-if="type === 'self'&&item.stat==3" @click="undel(item)" 
                                style="color:#F56C6C" type="text" size="small">恢复删除</el-button>
                        </div>
                    </div>
                    <div class="btn-container flex">
                        <div class="btn-c-left flex flex-align-center">
                            <div class="bcl-til">集市</div>
                            <div class="state flex flex-align-center">
                                <span v-if="type === 'self'&&item.market_publish==1 || type != 'self'&&item.stat!=3" class="state-item blue">
                                    <i class="el-icon-check"></i>已上架
                                </span>
                                <span v-if="type === 'self'&&item.market_publish==0  || type != 'self'&&item.stat==3" class="state-item">
                                    <i class="el-icon-close"></i>未上架
                                </span>
                            </div>
                            <el-button v-if="type === 'self'&&item.stat!=3&&item.market_publish==1" 
                                type="text" size="small" @click="changeSwitch(item)">下架</el-button>
                            <el-button v-if="type === 'self'&&item.stat!=3&&item.market_publish==0" 
                                type="text" size="small" @click="changeSwitch(item)">上架</el-button>
                        </div>
                        <div v-if="type === 'self'" class="btn-c-right flex flex-align-center">
                            <div class="bcl-til">销售手册</div>
                            <div class="state flex flex-align-center">
                                <span v-if="item.salebook_publish==1" class="state-item blue">
                                    <i class="el-icon-check"></i>已上架
                                </span>
                                <span v-if="item.salebook_publish==0" class="state-item">
                                    <i class="el-icon-close"></i>未上架
                                </span>
                            </div>
                            <div>
                                <el-button v-if="item.stat!=3&&item.salebook_publish==1" type="text" size="small" @click="saleBookSwitch(item)">下架</el-button>
                                <el-button v-if="item.stat!=3&&item.salebook_publish==0" type="text" size="small" @click="saleBookSwitch(item)">上架</el-button>
                            </div>
                            <div style="margin-left: 30px" class="state flex flex-align-center">
                                <span class="state-item">
                                    <i class="el-icon-check"></i>价格保密
                                </span>
                                <span style="color:B3B3B3;">（默认）</span>
                            </div>
                        </div>
                        <!-- <div v-if="type === 'self'" @click="joinAgent(item)" class="flex flex-align-center" style="margin-left:30px;cursor:pointer;">
                            <span style="color:#5074EE;">编辑可见渠道</span>
                        </div> -->
                        
                    </div>
                </div>
            </div>
            
        </div>
        <div v-if="false" class="no-info flex flex-align-center">尚未填写销售信息</div>
        <div v-if="true" class="table-wrap">
            <div class="tw-head flex">
                <div class="accessories flex flex-align-center flex-1">
                    <div @click="select(idx)" class="flex" v-for="(item1,idx) in selectList" :key="idx">
                        <div :class="{'active':selectIdx==idx}">{{item1.name}}</div>
                        <span v-if="selectList.length>1 && idx==0" class="title">附件：</span>
                    </div>
                </div>
                <div v-if="skusArr && skusArr.length>0" style="padding:3px 0;" class="flex">
                    <div v-if="item.stat==2">
                        <el-button style="font-size:16px;color:#5074EE;font-weight:600;"
                            @click="setSku()" type="text">编辑销售信息</el-button>
                    </div>
                    <!-- <div style="width:60px;">
                        <el-button @click="del(item.accessory[selectIdx])" v-if="selectIdx != 0" style="color:#F66F6A;margin-left:30px;" type="text">删除</el-button>
                    </div> -->
                </div>
            </div>
            <Skus v-if="skusFlag && skusArr && skusArr.length>0" v-model="skusArr" 
                :materials="materials" :colors="colors" :inCatAttrs="catAttrs" :viewOnly="true"></Skus>
            <div v-if="skusFlag && skusArr && skusArr.length===0" class="addSku">
                <i @click="setSku()" class="iconfont icon-tianjia"></i>
                <span @click="setSku()">完善销售信息</span>
            </div>
            <div class="btm-wrap flex">
                <div class="flex-1">
                    <span>成交总额</span>
                    <span class="price">￥{{item.sale_amount | priceFilt}}</span>
                </div>
                <!-- <div @click="goto('/factory/xsdd')">查看订单（{{item.orderNum || 0}}）</div> -->
                <div @click="goto('/factory/xsdd')">查看订单</div>
            </div>
        </div>
        <el-dialog :title="popTitle" :visible.sync="popVisible" 
            width="600px" :destroy-on-close="true">
            <JoinAgent v-if="popTitle=='编辑可见渠道'" :factory_product_no="item.id" @hide="popVisible=false"></JoinAgent>
            <DrawDesign v-else v-model="item.photo_sample" :itemId="item.id" @onSuccess="onSuccess" @hide="popVisible=false"></DrawDesign>
        </el-dialog>
    </div>  
</template>

<script>
import {sortBy} from 'lodash';
import { IMG_URL_PRE } from '@/config';
import { getFacCatDetail } from '@/service/item';
import { toggleSelfProduct,toggleSalebookSelfProduct,delSelfProduct,undelSelfProduct } from '@/service/factory';
import Skus from '@/components/sku/Skus.vue';
import DrawDesign from '@/components/factory/DrawDesign.vue'
import JoinAgent from '@/components/factory/JoinAgent.vue';
export default {
    components: {
        Skus, DrawDesign, JoinAgent
    },
    props:['item','materials','colors','type'],
    // props: {
    //     type: {
    //         type: String,
    //         default: 'notself'
    //     },
    //     item:Object,
    // },
    data() {
        return {
            imgUrl:IMG_URL_PRE,
            popVisible: false,
            popTitle: '',
            selectList:[
                {name:"主商品"},
            ],
            selectIdx:0,
            selectArr:[],
            skusArr:null,
            skusFlag:false,
            catAttrs:[],
        }
    },
    // watch:{
    //     item:{
    //         handler() {
    //             this.update();
    //         },
    //         deep: true
    //     }
    // },
    async created() {
        // console.log(this.item);
        this.catAttrs= await getFacCatDetail(this.item.cat.id);
        this.update();
    },
    mounted() {},
    methods: {
        update() {
            // console.log(666);
            this.item.expire_at = this.$moment(this.item.expire_at).format("YYYY/MM/DD");
            let selectList = [{name:"主商品"},];
            if (this.item.accessory && this.item.accessory.length>0) {
                this.item.accessory.forEach(e => {
                    if (this.type=='self') {
                        if (e.stat && e.stat==1 || e.stat==2) {
                            selectList.push({name:e.name});
                            this.selectList=selectList;
                        }
                    } else {
                        selectList.push({name:e.name});
                        this.selectList=selectList;
                    }
                });
            } else {
                this.selectIdx = 0;
                selectList = [{name:"主商品"},];
                this.selectList=selectList;
            }
            if (this.selectIdx==0) {
                this.$nextTick(() => {
                    this.skusArr = this.item.step_price;
                });
                this.skusFlag = true;
            } else {
                this.$nextTick(() => {
                    this.skusArr = this.item.accessory[this.selectIdx-1].step_price;
                });
                this.skusFlag = true;
            }
        },
        goto(path) {
            this.$router.push(path);
        },
        select(index) {
            this.selectIdx = index;
            this.skusFlag = false;
            this.skusArr=null;
            if (index == 0) {
                this.skusArr = this.item.step_price;
            } else {
                this.skusArr = this.item.accessory[index-1].step_price;
            }
            this.$nextTick(() => {
                this.skusFlag= true;
            });
        },
        setSku() {
            if (this.type === 'self') {
                if (this.selectIdx==0) {
                    this.$router.push('/factory/quote/'+this.item.id);
                } else {
                    this.$router.push('/factory/quote/'+this.item.id+"?type="+(this.selectIdx-1));
                }
            } else {
                // if (this.selectIdx==0) {
                //     this.$router.push('/factory/hzzpQuote/'+this.item.id);
                // } else {
                    
                // }
                this.$router.push('/factory/hzzpQuote/'+this.item.id+"?type="+(this.selectIdx-1));
            }
        },
        authentication(item) {
           this.popTitle = "打样认证";
            this.popVisible = true;
        },
        fillBom(item) {
            this.$router.push({
                path:"/mes",
                query:{
                    path:`product/ownProduct?InfoCode=${item.id}`
                }
            })
        },
        changeSwitch(item) {// 上下架
            let text=null,stat=null;
            if (item.market_publish==1) {
                text = "下架"; stat = 0;
            } else if (item.market_publish==0) {
                text = "上架"; stat = 1;
            }
            this.$confirm('确认是否'+text+'?', '温馨提示', { confirmButtonText: '确定',
            cancelButtonText: '取消', type: 'warning',
            }).then(() => {
                toggleSelfProduct({product_no:item.id,stat:stat}).then(()=>{
                    this.$emit('onSuccess');
                    // this.items[index].stat = stat;
                    this.$message({ type: 'success', message: text+'成功！' });
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            });
            console.log(item);
        },
        saleBookSwitch(item) { // 销售手册开关
            let text=null,stat=null;
            if (item.salebook_publish==1) {
                text = "下架"; stat = 0;
            } else if (item.salebook_publish==0) {
                text = "上架"; stat = 1;
            }
            this.$confirm('确认是否'+text+'?', '温馨提示', { confirmButtonText: '确定',
            cancelButtonText: '取消', type: 'warning',
            }).then(() => {
                toggleSalebookSelfProduct({product_no:item.id,stat:stat}).then(()=>{
                    this.$emit('onSuccess');
                    // this.items[index].stat = stat;
                    this.$message({ type: 'success', message: text+'成功！' });
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            });
            console.log(item);
        },
        onSuccess() {
            this.$emit('onSuccess');
        },
        edit(item) {
            this.$router.push({
                path:"/factory/zysp/submit",
                query:{id:item.id,}
            })
        },
        joinAgent(item) {
            this.popTitle = "编辑可见渠道";
            this.popVisible = true;
        },
        // 删除
        del(item) {
            this.$confirm('是否确认删除?', '温馨提示', { confirmButtonText: '确定',
            cancelButtonText: '取消', type: 'warning',
            }).then(() => {
                delSelfProduct({product_no:item.id}).then(()=>{
                    this.$emit('onSuccess');
                    this.$message({ type: 'success', message: '删除成功!' });
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            });
        },
        // 恢复删除
        undel(item) {
            this.$confirm('是否确认恢复删除的内容?', '温馨提示', { confirmButtonText: '确定',
            cancelButtonText: '取消', type: 'warning',
            }).then(() => {
                undelSelfProduct({product_no:item.id}).then(()=>{
                    this.$emit('onSuccess');
                    this.$message({ type: 'success', message: '恢复成功!' });
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            });
        },
        gotoDetail() {
            if (this.type === 'self') {
                this.$router.push('/factory/zysp/detail/'+this.item.id);
            } else {
                this.$router.push('/factory/hzzp/detail/'+this.item.id);
            }
        },
    },
    computed: {
        sortedData: function () {
            let sortedData = sortBy(this.data, ['materialId','colorId','sizeId','structureId'])
            return sortedData
        }
    }
}
</script>
<style scoped src="./style.css"></style>
<style scoped lang="less">
.hzzp-block {
    transition: all 0.2s;
}
.hzzp-block:hover {
    box-shadow: 0px 6px 16px rgba(0,0,0,0.16);
}
.hzzp-block {
    // padding-bottom: 63px;
    .state-icon {
        width: 80px;
        height: 70px;
        position: absolute;
        top: 0;
        left: 316px;
        cursor: pointer;
        img {
            width: 100%;
        }
    }
    .hzzp-header {
        height:75px;
        background:rgba(230,230,230,1);
        border:1px solid rgba(204,204,204,1);
        font-size: 16px;
        
        .outdate-time {
            font-size: 14px;
            margin-right: 77px;
            color: #808080;
            span {
                color: #F66F6A;
                font-size: 16px;
                font-weight: bold;
            }
        }
        .comp-name {
            color: #292929;
            span {
                color: #4D4D4D;
                font-weight: bold;
            }
        }
    }
    .hzzp-info {
        padding: 15px 25px;
        position: relative;
        .img-left {
            width: 220px;
            height: 220px;
            margin-right: 20px;
        }
        .info-right {
            color: #808080;
            position: relative;
            .info-right-top {
                .tx {
                    width:132px;
                    height:21px;
                    background:#FEA5A6;
                    background:linear-gradient(90deg,rgba(251,199,190,1) 0%,rgba(254,165,166,1) 100%);
                    border-radius:4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                }
                >div:not(.btn-container) {
                    margin-bottom: 10px;
                }
                .tit {
                    font-size: 18px;
                    color: #292929;
                    font-weight: bold;
                }
                .cat {
                    span {
                        display: inline-block;
                    }
                    span:nth-child(2) {
                        height: 14px;
                        line-height: 14px;
                        padding-left: 8px;
                        border-left: 1px solid #c0c4cc;
                    }
                }
            }
            .info-right-bottom {
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                .state {
                    height: 36px;
                    border-top: 1px solid #E6E6E6;
                    >div {
                        // border-left: 1px solid #707070;
                        padding: 0 24px;
                        &:first-child {
                            padding-left: 0;
                            border-left: 0;
                        }
                        .el-button {
                            font-size:14px;
                            color:#5074EE;
                        }
                    }
                    .state-item {
                        font-size: 12px;
                        color: #808080;
                        i {
                            font-size: 12px;
                            color: #808080;
                            border: 1px solid #808080;
                            margin-right: 5px;
                        }
                        &.blue {
                            // color: #5074EE;
                            i {
                                border: 1px solid #5074EE;
                                color: #5074EE;
                            }
                        }
                        &.green {
                            // color: #5074EE;
                            i {
                                border: 1px solid #5074EE;
                                color: #5074EE;
                            }
                        }
                    }
                }
                .btn-container {
                    padding: 10px 0 5px 0;
                    .btn-c-left {
                        // width:160px;
                        margin-right: 40px;
                    }
                    .btn-c-right {
                        // width:350px;
                    }
                    >div {
                        height: 40px;
                        padding: 0 10px;
                        background: #F5F5F5;
                        .bcl-til {
                            font-size: 14px;
                            font-weight: 600;
                            color: #4D4D4D;
                        }
                        .state {
                            border: none;
                            margin:0 10px;
                        }
                        .el-button {
                            font-size:14px;
                            color:#5074EE;
                        }
                    }
                }
            }
        }
    }
    .no-info {
        text-align: center;
        font-size: 36px;
        color: #ccc;
        margin-top: 77px;
    }
    .table-wrap {
        padding: 0 25px 25px 25px;
        margin-top: 15px;
        table {
            width: 100%;
            td,th {
                border: 1px solid #ccc;
                height: 40px;
                text-align: center;
            }
            td:last-child {
                color: #EE1818;
            }
        }
        .tw-head {
            background-color: #FAFAFF;
            border: 1px solid #DBE1F6;
            padding-right: 20px;
            .accessories {
                >div {
                    font-size: 14px;
                    color: #5074EE;
                    >span.title {
                        color: #4D4D4D;
                        line-height: 45px;
                        margin-left: 20px;
                    }
                    >div {
                        height: 45px;
                        line-height: 45px;
                        cursor: pointer;
                        padding: 0 20px;
                        position: relative;
                        &.active {
                            background: #ffffff;
                        }
                        &.active::before {
                            content: '';
                            position: absolute;
                            top: -1px; bottom: 0;
                            left: 0; right: 0;
                            border-top: 2px solid #5074EE;
                        }
                    }
                }
                >div:first-child>div {
                    padding: 0 20px;
                }
            }
        }
        .addSku {
            padding: 64px 0;
            text-align: center;
            font-size: 18px;
            color: #016FFF;
            border: 1px solid #DBE1F6;
            border-top: 0;
            span {
                cursor: pointer;
            }
            i {
                display: inline-block;
                font-size: 12px;
                cursor: pointer;
                position: relative;
                top: -2px;
                margin-right: 5px;
            }
            i::before {
                border: 1px solid #016FFF;
                border-radius: 50%;
                padding: 2px;
            }
        }
        
    }
    .btm-wrap {
        padding: 0 15px;
        height: 80px;
        line-height: 76px;
        font-size: 14px;
        color: #808080;
        background: #FFFCFA;
        border-left: 1px solid #F6EBDB;
        border-right: 1px solid #F6EBDB;
        border-bottom: 1px solid #F6EBDB;
        div {
            .price {
                font-size: 24px;
                color: #F66F6A;
                margin-left: 5px;
            }
            &:last-child {
                font-size: 16px;
                font-weight: 600;
                color: #5074EE;
                cursor: pointer;
            }
        }
    }
}
</style>